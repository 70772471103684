import React from 'react';
import { GetInvolvedChildPageProps } from './index';

const TextList = (
    {
        tghpworldsfairTypeTextlistText: text,
        tghpworldsfairTypeTextlistListItems: list,
    }: GetInvolvedChildPageProps
) => {

    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: text || '' }} />
            <ul>
                {list && list.map((item, index) => {
                    if (!item) {
                        return null;
                    }

                    const { text, url } = item;

                    return (
                        <li key={index}>
                            {url ?
                                <a href={url} target="_blank" rel="noopener noreferrer">
                                    {text}
                                </a>
                                :
                                text
                            }
                        </li>
                    );
                })}
            </ul>
        </>
    )
}

export default TextList;