import React from 'react';
import { GetInvolvedChildPageProps } from './index';
import TwitterIcon from '../../../images/social/twitter.inline.svg';
import EmailIcon from '../../../images/social/email.inline.svg';

const Contact = (
    {
        tghpworldsfairTypeContactText: text,
        tghpworldsfairTypeContactTwitterTitle: twitterTitle,
        tghpworldsfairTypeContactTwitterHandle: twitterHandle,
        tghpworldsfairTypeContactEmailTitle: emailTitle,
        tghpworldsfairTypeContactEmailAddress: emailAddress,
    }: GetInvolvedChildPageProps
) => {

    return (
        <>
            <header dangerouslySetInnerHTML={{ __html: text || '' }} />
            <div>
                <h2>
                    <TwitterIcon />
                    {twitterTitle}
                </h2>
                <a href={`https://twitter.com/${twitterHandle}`} target="_blank">@{twitterHandle}</a>
            </div>
            <div>
                <h2>
                    <EmailIcon />
                    {emailTitle}
                </h2>
                <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
            </div>
        </>
    )
}

export default Contact;