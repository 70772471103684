import React, { useEffect, useRef, useState } from 'react';
import { createWidget } from '@typeform/embed';
import { GetInvolvedChildPageProps } from './index';
import classNames from 'classnames';

const Typeform = (
    {
        tghpworldsfairTypeTypeformText: text,
        tghpworldsfairTypeTypeformTypeformId: formId,
    }: GetInvolvedChildPageProps
) => {
    const typeformRef = useRef<HTMLDivElement>(null);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (typeformRef.current && formId) {
            const widget = createWidget(formId, {
                container: typeformRef.current,
                onReady: () => setIsReady(true),
            });

            return () => widget.unmount();
        }
    }, [typeformRef]);

    return (
        <>
            <header dangerouslySetInnerHTML={{ __html: text || '' }} />
            <div
                className={classNames([
                    'typeform',
                    isReady && 'typeform--ready',
                ])}
                ref={typeformRef}
            />
        </>
    )
}

export default Typeform;