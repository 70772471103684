import React from 'react';
import { GetInvolvedChildPageProps } from './index';

const TextText = (
    {
        tghpworldsfairTypeTexttextText1: text1,
        tghpworldsfairTypeTexttextText2: text2,
    }: GetInvolvedChildPageProps
) => {

    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: text1 || '' }} />
            <div dangerouslySetInnerHTML={{ __html: text2 || '' }} />
        </>
    )
}

export default TextText;