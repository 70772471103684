import React from 'react';

import Typeform from './Typeform';
import TextList from './TextList';
import TextText from './TextText';
import Contact from './Contact';
export default { Typeform, TextList, TextText, Contact };

export type GetInvolvedChildPageProps = Queries.GetInvolvedTabDataFragment;

export const getInvolvedChildPageTypes = [
    'typeform',
    'text-list',
    'text-text',
    'contact',
] as const;
export type GetInvolvedChildPageTypes = typeof getInvolvedChildPageTypes[number];

export const GetInvolvedChildPageComponents: { [key in GetInvolvedChildPageTypes]: React.FC<GetInvolvedChildPageProps> } = {
    'typeform': Typeform,
    'text-list': TextList,
    'text-text': TextText,
    'contact': Contact,
}