import React from 'react';
import { graphql, PageProps } from 'gatsby'
import Layout from '../components/Layout';
import NotFoundPage from '../pages/404';
import { GetInvolvedChildPageComponents, GetInvolvedChildPageTypes } from '../components/GetInvolved/ChildPage';
import Tabs, { Tab } from '../components/Tabs';
import { WorldsFairHead } from '../components/Head';

const GetInvolved = ({ data: { getInvolved, tabPages } }: PageProps<Queries.GetInvolvedTemplateQuery>) => {
    if (!getInvolved || !tabPages.nodes) {
        return <NotFoundPage />;
    }

    const {
        title,
    } = getInvolved;

    const childPageTabs: Tab[] = [];
    for (const tabPage of tabPages.nodes) {
        if (!tabPage.tghpworldsfairType || !tabPage.title) {
            continue;
        }

        const type = tabPage.tghpworldsfairType as GetInvolvedChildPageTypes;
        const ChildPageComponent = GetInvolvedChildPageComponents[type];

        childPageTabs.push({
            label: tabPage.title,
            content: (
                <div className={`get-involved-tab get-involved-tab--${type}`}>
                    <ChildPageComponent {...tabPage} />
                </div>
            ),
        });
    }

    return (
        <Layout location="get-involved" seoData={getInvolved.seo}>
            <h1>{title}</h1>
            <Tabs tabs={childPageTabs} />
        </Layout>
    );
}

export default GetInvolved;

export const query = graphql`
    query GetInvolvedTemplate ($id: Int!) {
        getInvolved: wpPage(databaseId: { eq: $id }) {
            title
            ...SeoData
        }

        tabPages: allWpPage(
            filter: {
                template: { templateName: { eq: "Get Involved Tab" } }
                parentDatabaseId: { eq: $id }
            },
            sort: { fields: menuOrder, order: ASC }
        ) {
            nodes {
                ...GetInvolvedTabData
            }
        }
    }
`

export const Head = () => <WorldsFairHead />